import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/hanne/src/HS/strautmann.dev/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Impressum`}</h1>
    <h2>{`Angaben gemäß § 5 TMG`}</h2>
    <p>{`Hannes Strautmann`}<br />{`
Zur Steinleite 9`}<br />{`
96163 Gundelsheim`}<br /></p>
    <h2>{`Kontakt`}</h2>
    <p>{`Telefon: +49 (0) 179 76 40 818`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:info@strautmann.dev"
      }}>{`info@strautmann.dev`}</a><br /></p>
    <h2>{`Redaktionell verantwortlich`}</h2>
    <p>{`Hannes Strautmann`}<br />{`
Zur Steinleite 9`}<br />{`
96163 Gundelsheim`}<br /></p>
    <h2>{`Verbraucherstreitbeilegung / Universalschlichtungsstelle`}</h2>
    <p>{`Ich bin nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.`}</p>
    <p>{`Quelle: `}<a parentName="p" {...{
        "href": "https://www.e-recht24.de/impressum-generator.html"
      }}>{`https://www.e-recht24.de/impressum-generator.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      